import { Children, cloneElement, memo, useCallback } from "react";
import PropTypes from "prop-types";
import { animated, useTransition } from "react-spring";
import classNames from "classnames";
import "./SidePanel.scss";
import * as ReactDOM from "react-dom";
import { isServerSide } from "app/utils/utils";
import useBlockScroll from "app/utils/hooks/useBlockScroll";

/**
 * ATTENTION:
 * Ne pas mettre de state dans cette classe sinon l'autoFocus des champs input
 * ne fonctionnera pas sur iOS (iPhone, iPad).
 *
 * Use Drawer instead
 */
const SidePanel = ({
	children,
	isOpen,
	onClose,
	direction,
	disableBlockViewPortScroll,
	className,
	width = "100%",
}) => {
	const isRightToLeft = direction === "rtl";
	const isBottomToTop = direction === "btt";
	let transformValue;

	if (isBottomToTop) {
		transformValue = "translateY(100%)";
	} else if (isRightToLeft) {
		transformValue = "translateX(100%)";
	} else {
		transformValue = "translateX(-100%)";
	}

	const transitions = useTransition(isOpen, null, {
		from: { transform: transformValue },
		enter: { transform: isBottomToTop ? "translateY(0px)" : "translateX(0px)" },
		leave: { transform: transformValue },
		option: {
			delay: 75,
			duration: 200,
		},
	});

	useBlockScroll({ isModalOpen: isOpen && !disableBlockViewPortScroll });

	const hideSidePanel = useCallback(() => {
		onClose();
	}, []);

	const asideClass = classNames(
		{
			"side-panel": true,
			"side-panel--rtl": direction === "rtl",
			"side-panel--pushed": isOpen,
		},
		className
	);

	const childrenWithProps = Children.map(children, child => {
		return cloneElement(child, {
			hideSidePanel,
			isOpen,
		});
	});

	return (
		!isServerSide &&
		ReactDOM.createPortal(
			transitions.map(({ item, key, props }) =>
				item ? (
					<animated.div
						style={{
							...props,
							width: width,
							right: isRightToLeft ? 0 : "none",
							left: isRightToLeft ? "auto" : 0,
						}}
						key={key}
						className={asideClass}
						data-testId="side-panel"
					>
						{childrenWithProps}
					</animated.div>
				) : null
			),
			document.getElementById("perfectstay")
		)
	);
};

SidePanel.propTypes = {
	isOpen: PropTypes.bool,
	width: PropTypes.string,
	onClose: PropTypes.func,
	direction: PropTypes.oneOf(["rtl", "ltr"]),
	className: PropTypes.string,
};

export default memo(SidePanel);
